;(function() {
"use strict";

/*
 * Simple directive to make a button navbar at the bottom of a page. Replaces
 * all the footer templates.
 *
 * <footer-navbar>
 *   <button />
 *   <button />
 *   <button />
 * </footer-navbar>
 */
angular.module('tmr').directive('footerNavbar', function () {

    var directive = {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: "directive/footer-navbar.html",
    };

    return directive;
});
}());
