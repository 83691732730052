;(function() {
"use strict";

'use strict';

angular.module('tmr', [
    'ngAnimate', 'ui.router', 'mm.foundation', 'am.multiselect',
    'ngMessages', 'ngAria', 'duScroll', 'ui.validate', 'vcRecaptcha',
    'tandibar/ng-rollbar'
]);

angular.module('tmr').constant('RouterStates', {
    admin: {
        url: '/admin',
        views: {
            content: {
                templateUrl: 'partials/admin/content.html',
                controller: 'AdminController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Admin',
            next: 'home',
        },
    },

    login: {
        url: '/login',
        views: {
            content: {
                templateUrl: 'partials/login/content.html',
                controller: 'LoginController',
            },
        },
        data: {
            canHaveUser: true,
            title: 'Login',
            next: 'admin',
        },
    },

    home: {
        url: '/home',
        views: {
            content: {
                templateUrl: 'partials/home/content.html',
                controller: 'HomeController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Home',
            prev: 'admin',
        },
    },

    household: {
        url: '/household',
        views: {
            content: {
                templateUrl: 'partials/household/content.html',
                controller: 'HouseholdController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Household',
            prev: 'home',
            next: 'vehicles',
        },
    },

    vehicles: {
        url: '/vehicles',
        views: {
            content: {
                templateUrl: 'partials/vehicles/content.html',
                controller: 'VehiclesController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Vehicles',
            prev: 'household',
            next: 'home',
        },
    },

    travel: {
        url: '/travel/:personId',
        views: {
            content: {
                templateUrl: 'partials/travel/content.html',
                controller: 'TravelController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Travel',
            prev: 'home',
            next: 'trips',
        },
    },

    trips: {
        url: '/trips/:personId',
        views: {
            content: {
                templateUrl: 'partials/trips/content.html',
                controller: 'TripsController',
            },
        },
        data: {
            mustHaveUser:    true,
            title: 'Travel Diary',
            prev: 'travel',
            next: 'comments',
        },
    },

    comments: {
        url: '/comments/:personId',
        views: {
            content: {
                templateUrl: 'partials/comments/content.html',
                controller: 'CommentsController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Comments',
            prev: 'trips',
            next: 'home',
        },
    },

    saved: {
        url: '/saved',
        views: {
            content: {
                templateUrl: 'partials/saved/content.html',
                controller: 'SavedController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Saved',
        },
    },

    completed: {
        url: '/completed',
        views: {
            content: {
                templateUrl: 'partials/completed/content.html',
                controller: 'TenantAwareController',
            },
        },
        data: {
            mustHaveUser: true,
            title: 'Completed',
        },
    },

    expired: {
        url: '/expired',
        views: {
            content: {
                templateUrl: 'partials/expired/content.html',
            },
        },
        data: {
            canHaveUser:    false,
            title: 'Expired',
        },
    },

    deleted: {
        url: '/deleted',
        views: {
            content: {
                templateUrl: 'partials/deleted/content.html',
                controller:  'TenantAwareController',
            },
        },
        data: {
            canHaveUser: true,
            title: 'Deleted',
        },
    },

    notfound: {
        url: '/404',
        templateUrl: '404.html',
        data: {
            canHaveUser: true,
            title: 'Not found',
        },
    },
});

angular.module('tmr')
    .constant('Config', {
    })
    .config(['$animateProvider', $animateProvider => {
        $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
    }])
    .config(['$uiViewScrollProvider', $uiViewScrollProvider => {
        $uiViewScrollProvider.useAnchorScroll();
    }])
    .config(['$urlRouterProvider', $urlRouterProvider => {
        $urlRouterProvider.otherwise("/login");
    }])
    .config(['$httpProvider', $httpProvider => {
        $httpProvider.interceptors.push(['$q', '$window', ($q, $window) => {
            // Intercept any maintenance mode errors we get, and immediately
            // bail to the login screen.
            return {
                responseError: rejection => {
                    if ((rejection.status == 503)
                        && (rejection.data.code == 'maintenance')) {
                          $window.location.href = '/#/login';
                          $window.location.reload();
                    }
                    return $q.reject(rejection);
                }
            }
        }])
    }])
    .config(['$stateProvider', 'RouterStates', ($stateProvider, states) => {
       _.each(states, (properties, state) => {
            _.defaults(properties, {
                data: { },
            });
            var data = properties.data;
            if (! _.has(data, 'canHaveUser') &&
                ! _.has(data, 'mustHaveUser')) {
                throw(properties.url + " must have either canHaveUser or mustHaveUser specified");
            }
            if (! _.has(data, 'title')) {
                throw(properties.url + " must title");
            }
            $stateProvider.state(state, properties);
       });
    }])
    .config(['RollbarProvider', RollbarProvider => {
        if( tmr.environment !== 'development' || tmr.rollbar_active_for_dev ) {
            RollbarProvider.init({
                accessToken:                tmr.rollbar_access_token,
                captureUncaught:            true,
                captureUncaughtExceptions:  true,
                captureUnhandledRejections: true,
                payload: {
                    environment:  tmr.environment,
                    code_version: tmr.code_version,
                    // This also gets decorated with 'person' info in the User factory on login or load
                },
            });
        }
        else {
            RollbarProvider.deinit();
        }
    }]);


angular.module('tmr').run(['$log', '$rootScope', '$state', '$window', 'Config', 'User',
    function($log, $rootScope, $state, $window, config, user) {

        function verifyUser(event, toState, toParams,
                                   fromState, fromParams, options) {
            $log.debug(`verifyUser: ${fromState.name} -> ${toState.name}`);

            var canHaveUser  = toState.data.canHaveUser;
            var mustHaveUser = toState.data.mustHaveUser;
            var defaultState = 'login';
            var expiredState = 'expired';

            if (!canHaveUser && !mustHaveUser) {
                $log.debug("Forcing user logout");
                user.logout();  // can't have user - log them out
                return;         // continue with planned state change
            }

            // If there is an existing user we can return immediately.
            if (user.isLoggedIn()) {
                $log.debug("We have a user already. Continuing.");
                return;
            }

            // Check if we have a jwt in the session.
            if (!user.hasSession()) {
                if (canHaveUser) {
                    $log.debug("We don't have a user, but don't need one. Continuing.");
                    return;
                }
                else {
                    $log.debug("We need to be have a user, but don't have one. Bailing");
                    event.preventDefault();
                    $state.go(defaultState);
                }
            }

            // We have a session user, so we need to load it. This involves a
            // backend call, so it returns a promise, which means we must abort
            // the state change for now.
            event.preventDefault();
            user.load().then(
                ok => {
                    // We have a user, continue with the state change
                    $log.debug("Loaded user, transitioning after load");
                    $state.go(toState, toParams, options);
                },
                failure => {
                    $log.info("User load failed, logging out", failure);
                    user.logout();
                    if (mustHaveUser) {
                        $log.info("bailing");
                        $state.go(expiredState, toParams, options);
                    }
                    else {
                        $log.debug("continuing without user");
                        $state.go(toState, toParams, options);
                    }
                }
            );
        }

        // Custom functions for underscore
        _.mixin({
            arrayInsert: function(array, object, index) {
                array.splice(index, 0, object);
                return array;
            },

            arrayRemove: function(array, object) {
                var index = array.indexOf(object);
                if (index >= 0) {
                    array.splice(index, 1);
                }
                return array;
            },

            getPath: function(object, path, dflt = undefined) {
                var keys = path.split('.');
                for (var i = 0; i < keys.length; i++) {
                    var key = keys[i];
                    if (! _.has(object, key)) {
                        return dflt;
                    }
                    object = object[key];
                }
                return object;
            },
        });

        $rootScope.$on('$stateChangeStart', verifyUser);
        $rootScope.$on('$stateChangeSuccess', function(event, toState, fromState) {
            $rootScope.title = toState.data.title;
        });
    }
]);

// Really not sure of the best place to put this code
// If require('electron') succeeds than means we're running inside the electron app,
// and we can do whatever renderer-side setup needs going.
try {
    const { webFrame } = require('electron');

    // Running under the electron app. In electron terms, this is the "renderer" process.

    // Enable zooming in the electron app (eg. pinch to zoom)
    webFrame.setVisualZoomLevelLimits(1, 3);
}
catch (err) {
    // Not running under the electron app. This is fine.
};
}());
